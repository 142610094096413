import {
  DateFilterType,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemIsPlaceholderData,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  StringFilter,
  TextFilterType
} from '../../../types';
import { TagID } from '../../tags/tagsTypes';
import {
  ProjectBurning,
  ProjectFavorite,
  ProjectPerformerIdsFilter,
  ProjectRankedNameFilter
} from './projectsFields';
import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

import { UpdateIndexQueryItemCacheAction } from '../../common/hooks/base/reactQuery/useIndexQuery';
import { UserID, UserNanoID } from '../../users/usersTypes';
import { TeamNanoID } from '../../teams/teamsTypes';

export interface FetchProjectsFilters {
  burning?: ProjectBurning;
  createdAt?: DateFilterType;
  favorite?: ProjectFavorite;
  implementationDate?: DateFilterType;
  performerIds?: ProjectPerformerIdsFilter;
  name?: TextFilterType;
  rankedName?: ProjectRankedNameFilter;
  status?: TextFilterType;
  tagIds?: TagID[];
  memberNanoIds?: UserNanoID[];
  memberFullName?: StringFilter;
  producerIds?: UserID[];
  teamNanoIds?: TeamNanoID[];
  id?: IdFilterType;
}

export const enum FetchProjectsSortableFields {
  ID = 'id',
  NAME = 'name',
  IMPLEMENTATION_DATE = 'implementation_date',
  STATUS = 'status',
  STATUS_NUMBER = 'status_number',
  ESTIMATE_COST = 'estimate_cost_cents',
  CREATED_AT = 'createdAt'
}

export const enum FetchProjectsFilterFields {
  BURNING = 'burning',
  CREATED_AT = 'createdAt',
  FAVORITE = 'favorite',
  IMPLEMENTATION_DATE = 'implementationDate',
  PERFORMER_IDS = 'performerIds',
  RANKED_NAME = 'rankedName',
  STATUS = 'status',
  STATUS_IN = 'status.in',
  TAG_IDS = 'tagIds'
}

export const enum FetchProjectsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  IMPLEMENTATION_DATE_ASC = 'IMPLEMENTATION_DATE_ASC',
  IMPLEMENTATION_DATE_DESC = 'IMPLEMENTATION_DATE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  STATUS_NUMBER_ASC = 'STATUS_NUMBER_ASC',
  STATUS_NUMBER_DESC = 'STATUS_NUMBER_DESC',
  STATUS_NUMBER_ASC_ASC = 'STATUS_NUMBER_ASC_ASC',
  STATUS_NUMBER_DESC_DESC = 'STATUS_NUMBER_DESC_DESC',
  ESTIMATE_COST_ASC = 'ESTIMATE_COST_CENTS_ASC',
  ESTIMATE_COST_DESC = 'ESTIMATE_COST_CENTS_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchProjectsGqlQuery = FetchItemsGqlQuery;
export type FetchProjectGqlQuery = FetchItemGqlQuery;
export type FetchProjectsCacheKey = FetchItemsCacheKey;
export type FetchProjectCacheKey = FetchItemCacheKey;
export type FetchToggleProjectFavoriteCacheKey = FetchItemCacheKey;

export type FetchProjectsFetched = FetchItemsFetched;
export type FetchProjectsErrorMessage = FetchItemsErrorMessage;
export type FetchProjectsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchProjectsPage = FetchItemsPage;
export type FetchProjectsLimit = FetchItemsLimit;
export type FetchProjectsSort = FetchItemsSort; // FetchProjectsSortTypes[];
export type FetchProjectsTotalCount = FetchItemsTotalCount;
export type FetchProjectsFilterProjects =
  FetchItemsFilterItems<FetchProjectsFilters>;
export type FetchProjectsClearProjectsFilters = FetchItemsClearItemsFilters;
export type FetchProjectsSortProjects = FetchItemsSortItems;
export type FetchProjectsPaginateProjects = FetchItemsPaginateItems;
export type FetchProjectsPrefetchProjects = FetchItemsPrefetchItems;
export type FetchProjectsShowProject = FetchItemsShowItem;
export type FetchProjectsEditProject = FetchItemsEditItem;

export type FetchProjectFetched = FetchItemFetched;
export type FetchProjectIsPlaceholderData = FetchItemIsPlaceholderData;

export type FetchProjectsChecked = Checked;
export type FetchProjectsCheckedHash = CheckedHashItem;
export type FetchProjectsOnSetCheckedIds = OnSetCheckedIds;
export type FetchProjectsCheckedAll = CheckedAll;
export type FetchProjectsOnCheckAll = OnCheckAll;

export type UpdatePaginatedProjectsItemCacheAction<ItemType> =
  UpdateIndexQueryItemCacheAction<ItemType>;
